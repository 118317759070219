import React from "react";
import img from "../images/MPC-NEW-LOGO-white-background-21.jpg";

const AboutUs = () => {
  return (
    <>
      <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id="about">
        <div
          className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left"
          data-aos="fade-up"
        >
          <div
            className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8"
            data-aos="zoom-in"
            data-aos-delay="500"
          >
            <h3 className="text-3xl  text-blue-900 font-bold" style={styles.aboutUs}>About Us</h3>
            <div>
              <p className="my-3 text-xl font-semibold">
                We are Marketing and Promotions Concept, a full-service events
                management and marketing agency.
              </p>
            </div>

            <div>
              <p className="my-3 text-xl  font-semibold">
                We can equip your company with larger publicity and online
                footprint
              </p>
            </div>
            <h3
              className="text-2xl  text-blue-900 font-bold"
              style={{ marginTop: "60px" }}
            >
              Location
            </h3>
            <p className="my-3 text-xl  font-semibold">
              We currently operate in <br/>
               • Lagos, Nigeria <br/>
              With plans to expand across West Africa in the
              coming years. Our network spans Africa and the Middle East,
              leveraging partner capabilities to enhance operations and share
              resources. We cover all aspects of experiential marketing,
              including design, communication, and environment, to deliver the
              ultimate experience for the brands we serve
            </p>
            <h3
              className="text-2xl  text-blue-900 font-bold"
              style={{ marginTop: "60px" }}
            >
              Our Experience
            </h3>
            <p className="my-3 text-xl font-semibold">
              Since inception, we have built a reputation for delivering
              creative and high-quality activations that meet our clients'
              needs. Our deep understanding of market dynamics, consumer
              lifestyles, and brand equities allows us to create a unique brand
              experience (UBX) that ensures a strong return on investment (ROI)
              in all our activations
            </p>
          </div>
          <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center">
            <img
              alt="card img"
              className="rounded-t float-right"
              src={img}
              style={styles.MPC_LOGO}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const styles = {
  MPC_LOGO: {
    backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3))",
    boxShadow: "10px 10px 10px rgba(14, 12, 12, 0.534)",
    borderRadius: "10%",
  },
  aboutUs: {
    fontFamily: "Karla, sans-serif",
    textShadow: "2px 4px 8px rgba(0, 0, 0, 0.3)",
    fontVariant: "small-caps",
    color: "#3681D1",
  },
};

export default AboutUs;
