import React from "react";
import UserCard from "./UserCard";

const UserList = ({ user }) => {
  return (
    <div className="title">
      <div className="userlist">
        {user.map((el) => (
          <div>
            <UserCard users={el} key={el.id} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default UserList;