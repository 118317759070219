import React from "react";

import MPC_LOGO from "../images/MPC-NEW-LOGO-white-background-21.jpg";

const Home = () => {
  const introData = [
    {
      heading: "Marketing",
      text: "Boost your brand with MPC International! Drive engagement and convert prospects into loyal customers. Start now",
    },
    {
      heading: "Promotion",
      text: "Elevate your visibility with us! Attract new clients and maximize ROI. Contact us today",
    },
  ];
  return (
    <div
      style={{ paddingTop: "100px" }}
      className="relative bg-gradient-to-br from-white to-blue min-h-screen flex justify-center items-center overflow-hidden"
      id="home"
    >
      <div className="absolute top-0 left-0 w-full h-full">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 320"
          className="w-full absolute bottom-0"
        >
          <path
            fill="#3681D1"
            fillOpacity="1"
            d="M0,96L48,85.3C96,75,192,53,288,69.3C384,85,480,139,576,165.3C672,192,768,192,864,186.7C960,181,1056,171,1152,138.7C1248,107,1344,53,1392,26.7L1440,0L1440,320L1392,320C1344,320,1248,320,1152,320C1048,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
          ></path>
        </svg>
      </div>
      <div
        className="z-10 text-white text-center px-4 md:px-12 lg:px-24"
        style={styles.mainWrapper}
      >
        <img
          src={MPC_LOGO}
          alt="Company Logo"
          className="w-48 h-48 md:w-64 md:h-64 mx-auto mb-8"
          style={styles.MPC_LOGO}
        />

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8">
          {introData.map((data, index) => (
            <div
              key={index}
              className=" rounded-lg p-6 shadow-md animate__animated animate__fadeInLeft"
              style={styles.MPC_intro}
            >
              <h2 style={styles.introHeading}>{data.heading}</h2>
              <p style={styles.introText}>{data.text}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const styles = {
  mainWrapper: {
    justifyContent: "space-between",
    gap: "30px",
    alignItems: "center",
  },
  MPC_LOGO: {
    backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3))",
    boxShadow: "10px 10px 10px rgba(14, 12, 12, 0.534)",
    borderRadius: "10%",
  },
  MPC_intro: {
    boxShadow: "10px 10px 10px rgba(14, 12, 12, 0.534)",
    background: "white",
    marginBottom: "20px",
    // border: "solid 1px lightgray",
  },
  introHeading: {
    fontFamily: "Karla, sans-serif",
    textShadow: "2px 4px 8px rgba(0, 0, 0, 0.5)",
    fontVariant: "small-caps",
    color: "#0c0b0bfd",
    fontSize: "25px",
    fontWeight: "bold",
  },
  introText: {
    fontFamily: "Karla, sans-serif",
    color: "#0c0b0bfd",
    fontSize: "20px",
  },
};

export default Home;
