import React, { useEffect } from "react";

const img1 =
  "https://i.pinimg.com/736x/bb/e3/15/bbe3158c089a746c08e8a9877880ddb7.jpg";
const img2 =
  "https://images.unsplash.com/photo-1620932934088-fbdb2920e484?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8bmlnZXJpYW4lMjBtYW58ZW58MHx8MHx8fDA%3D";

const UserReview = () => {
  useEffect(() => {
    const handleScroll = () => {
      const cards = document.querySelectorAll(".feature-card");
      cards.forEach((card) => {
        const rect = card.getBoundingClientRect();
        if (rect.top < window.innerHeight - 100) {
          card.style.opacity = 1;
          card.style.transform = "translateY(0)";
        }
      });
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const Reviews = [
    {
      name: "Tony Osakwe",
      img: img1,
      comment:
        "MPC International transformed our marketing strategy and delivered outstanding results. Our customer engagement has never been higher!",
    },

    {
      name: "Alabi Godswill",
      img: img2,

      comment:
        "The promotional services provided by MPC International exceeded our expectations. We saw a significant increase in visibility and new client acquisition.",
    },
  ];

  return (
    <div style={styles.featureSection}>
      <div style={styles.radialBackground}>
        <h2 style={styles.userReview}>User Review</h2>
      </div>

      {Reviews.map((data, index) => (
        <div
          key={index}
          className="feature-card"
          style={styles.featureCard}
          onMouseEnter={(e) => {
            e.currentTarget.style.transform = "scale(1.05)";
            e.currentTarget.style.boxShadow = "0 15px 25px rgba(0, 0, 0, 0.3)";
            e.currentTarget.querySelector(
              ".feature-card-shape"
            ).style.clipPath = "ellipse(100% 90% at 50% 0%)";
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.transform = "";
            e.currentTarget.style.boxShadow = styles.featureCard.boxShadow;
            e.currentTarget.querySelector(
              ".feature-card-shape"
            ).style.clipPath = "ellipse(120% 100% at 50% 0%)";
          }}
        >
          <div style={styles.featureCardContent}>
            <div style={styles.imgContainer}>
              <div style={styles.imgWrapper}>
                <img alt="card img" style={styles.imgWrapper} src={data.img} />
              </div>
            </div>
            <h2 style={styles.featureCardTitle}>{data.name}</h2>
            <p style={styles.featureCardDescription}>{data.comment}</p>
          </div>
          <div
            className="feature-card-shape"
            style={styles.featureCardShape}
          ></div>
        </div>
      ))}
    </div>
  );
};

const styles = {
  featureSection: {
    position: "relative",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center",
    padding: "100px 20px",
    overflow: "hidden",
  },
  userReview: {
    fontFamily: "Karla, sans-serif",
    textShadow: "2px 4px 8px rgba(0, 0, 0, 0.5)",
    fontVariant: "small-caps",
    color: "black",
    textAlign: "center",
    fontWeight: "bold",
    fontSize: "25px",
    marginTop: "50px",
  },
  featureCard: {
    position: "relative",
    width: "300px",
    height: "400px",
    backgroundColor: "#3681D1",
    borderRadius: "20px",
    overflow: "hidden",
    margin: "20px",
    transition: "transform 0.4s ease, box-shadow 0.4s ease",
    cursor: "pointer",
    boxShadow: "0 10px 20px rgba(0, 0, 0, 0.2)",
    opacity: 0,
    transform: "translateY(50px)",
  },
  featureCardVisible: {
    opacity: 1,
    transform: "translateY(0)",
  },
  featureCardContent: {
    position: "relative",
    padding: "20px",
    color: "#333",
    textAlign: "center",
    zIndex: 2,
  },
  imgContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  imgWrapper: {
    backgroundColor: "lightgray",
    width: "50px",
    height: "50px",
    borderRadius: "50%",
  },
  featureCardTitle: {
    fontSize: "2rem",
    marginBottom: "20px",
    color: "black",
    fontFamily: "Karla, sans-serif",
    textShadow: "2px 4px 8px rgba(0, 0, 0, 0.5)",
    fontVariant: "small-caps",
  },
  featureCardDescription: {
    fontSize: "1.2rem",
    color: "black",
  },
  featureCardShape: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "white",
    clipPath: "ellipse(120% 100% at 50% 0%)",
    zIndex: 1,
    transition: "all 0.4s ease",
  },
  featureCardShapeHover: {
    clipPath: "ellipse(100% 90% at 50% 0%)",
  },
  radialBackground: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "radial-gradient(circle, white, lightgray)",
    zIndex: 0,
  },
  "@media (maxWidth: 768px)": {
    featureCard: {
      width: "250px",
      height: "350px",
    },
    featureCardTitle: {
      fontSize: "1.8rem",
    },
    featureCardDescription: {
      fontSize: "1rem",
    },
  },
};
export default UserReview;
