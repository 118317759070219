import React, { useState, useEffect } from "react";

import img1 from "../../MPC_Images/IMG-20240529-WA0047.jpg";
import img2 from "../../MPC_Images/IMG-20240529-WA0056.jpg";
import img3 from "../../MPC_Images/IMG-20240601-WA0020.jpg";
import img4 from "../../MPC_Images/IMG-20240601-WA0019.jpg";
import img5 from "../../MPC_Images/IMG-20240601-WA0016.jpg";
import img6 from "../../MPC_Images/IMG-20240601-WA0013.jpg";
import img7 from "../../MPC_Images/IMG-20240529-WA0067.jpg";
import img8 from "../../MPC_Images/IMG-20240529-WA0060.jpg";
import img9 from "../../MPC_Images/IMG-20240529-WA0065.jpg";
import img10 from "../../MPC_Images/IMG-20240530-WA0003.jpg";

const images = [img1, img2, img3, img4, img5, img6, img7, img8, img9, img10];

// Fisher-Yates shuffle algorithm
const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
};

shuffleArray(images);

const UserCard = (props) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000); // Change image every 3 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="box">
      <div className="Card">
        <div className="imgBx">
          <img src={images[currentIndex]} alt="User" />
        </div>
        <div className="details">
          <h2 className="username" style={{color: "black"}}>
            {props.users.name}
          </h2>
        </div>
      </div>
    </div>
  );
};

export default UserCard;
