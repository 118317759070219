import axios from "axios";
import { useEffect, useState } from "react";
import "./UsersStyle.css";
import { Routes, Route } from "react-router-dom";
import UserList from "./UserList";
import AboutUsers from "./AboutUsers";

const data = [
  {
    id: 1,
    name: "Our Catalog",
    username: "Bret",
    email: "Sincere@april.biz",
    address: {
      street: "Kulas Light",
      suite: "Apt. 556",
      city: "Gwenborough",
      zipcode: "92998-3874",
      geo: {
        lat: "-37.3159",
        lng: "81.1496",
      },
    },
    phone: "1-770-736-8031 x56442",
    website: "hildegard.org",
    company: {
      name: "Romaguera-Crona",
      catchPhrase: "Multi-layered client-server neural-net",
      bs: "harness real-time e-markets",
    },
  },
];
function Users() {
  const [users, setUsers] = useState([]);
  useEffect(() => {
    setUsers(data);
  }, [users]);
  return (
    <div className="UserApp">
      <UserList user={users} />
      <AboutUsers user={users} />
    </div>
  );
}

export default Users;
