import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import NavBar from "../Navbar/NavBar";

import img1 from "../../MPC_Images/IMG-20240529-WA0068.jpg";
import img2 from "../../MPC_Images/IMG-20240601-WA0017.jpg";
import img3 from "../../MPC_Images/IMG-20240529-WA0054.jpg";
import img4 from "../../MPC_Images/IMG-20240529-WA0049.jpg";
import img5 from "../../MPC_Images/IMG-20240601-WA0020.jpg";
import img6 from "../../MPC_Images/IMG-20240529-WA0055.jpg";
import img7 from "../../MPC_Images/IMG-20240601-WA0015.jpg";
import img8 from "../../MPC_Images/IMG-20240601-WA0018.jpg";
import AOS from "aos";
import "aos/dist/aos.css";

// Array of background images
const backgroundImages = [img1, img2, img3, img4, img5, img6, img7, img8];

const Hero = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    AOS.init({
      duration: 1200,
      offset: 200,
      easing: "ease-in-out-sine",
    });

    const interval = setInterval(() => {
      setCurrentImageIndex(
        (prevIndex) => (prevIndex + 1) % backgroundImages.length
      );
    }, 3000); // Change image every 3 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <div
        className="hero relative overflow-hidden"
        id="hero"
        style={{ height: "100vh" }}
      >
        <NavBar />
        <div className="background-slider absolute inset-0 z-0">
          {backgroundImages.map((image, index) => (
            <div
              key={index}
              className={`absolute inset-0 transition-opacity duration-1000 ease-in-out ${
                index === currentImageIndex ? "opacity-100" : "opacity-0"
              }`}
              style={{
                backgroundImage: `url(${image})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            ></div>
          ))}
        </div>

        <div
          className="relative z-10 m-auto mx-4 mt-8 lg:mt-4 p-2 md:p-12 h-5/6"
          data-aos="zoom-in"
        >
          <div
            className="flex flex-col lg:flex-row py-8 justify-between text-center lg:text-left"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              className="lg:w-1/2 flex flex-col justify-center"
              data-aos="zoom-in"
              data-aos-delay="200"
              style={{ alignItems: "center" }}
            >
              <h1
                className="mb-5  font-bold text-white"
                style={{
                  backgroundColor: "rgba(0, 0, 0, 0.)",
                  borderRadius: "10%",
                  padding: "10px",
                  fontSize: "32px",
                }}
              >
                You create the company model <br />
                We promote the business idea
              </h1>

              <div className="mb-4 space-x-0 md:space-x-2 md:mb-8">
                <Link
                  to="/contact"
                  className="text-white hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0"
                  style={{ backgroundColor: "#3681d1", fontWeight: "bold" }}
                >
                  Learn more
                  <svg
                    className="w-4 h-4 ml-1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
