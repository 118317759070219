import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import img1 from "../MPC_Images/IMG-20240529-WA0047.jpg";
import img2 from "../MPC_Images/IMG-20240529-WA0048.jpg";
import img3 from "../MPC_Images/IMG-20240530-WA0003.jpg";

const Portfolio = () => {
  const images = [img1, img2, img3]; // Add your image imports here

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, 5000); // Change image every 5 seconds

    return () => clearInterval(interval);
  }, [images.length]);

  const styles = {
    portfolioWrapper: {
      position: "relative",
      width: "100%",
      height: "100vh",
      overflow: "hidden",
    },
    backgroundSlider: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundSize: "cover",
      backgroundPosition: "center",
      transition: "background-image 1s ease-in-out",
      backgroundImage: `url(${images[currentImageIndex]})`,
    },
    overlay: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      background: "rgba(0, 0, 0, 0.5)", // Dark overlay
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    content: {
      color: "white",
      textAlign: "center",
      padding: "20px",
    },
    title: {
      fontSize: "3rem",
      fontWeight: "bold",
      marginBottom: "20px",
    },
    description: {
      fontSize: "1.5rem",
      marginBottom: "20px",
      backgroundColor: "rgba(0, 0, 0, 0.65)",
    },
    learnMore: {
      fontSize: "1.2rem",
      color: "#fff",
      background: "#3681d1",
      padding: "10px 20px",
      borderRadius: "5px",
      textDecoration: "none",
      transition: "background 0.3s",
    },
    learnMoreHover: {
      background: "#2a6cb3",
    },
  };
 

  return (
    <>
      <div style={styles.portfolioWrapper} id="portfolio">
        <div style={styles.backgroundSlider}>
          <div style={styles.overlay}>
            <div style={styles.content}>
              <h2 style={styles.title}>We are simple</h2>
              <p style={styles.description}>
                We are a reliable and experienced agency with deep local
                knowledge and a global perspective. We help you articulate,
                plan, and execute your marketing strategies.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};



export default Portfolio;
