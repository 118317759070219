import React from "react";
import img1 from "../MPC_Images/PnG.jpg";
import img2 from "../MPC_Images/Society.jpg";
import img3 from "../MPC_Images/Nestle.jpg";
import img4 from "../MPC_Images/Johnson.jpg";
import img5 from "../MPC_Images/SkyeBank.jpg";
import img6 from "../MPC_Images/DeUnitedIndomie.gif";
import img7 from "../MPC_Images/Samsung.jpg";
import img8 from "../MPC_Images/Glaxo.jpg";
import img9 from "../MPC_Images/IFA.gif";
import img10 from "../MPC_Images/Mash.jpg";
import img11 from "../MPC_Images/Etisalat.jpg";
import img12 from "../MPC_Images/Lornamead.jpg";
import img13 from "../MPC_Images/Firman2.png";
import img14 from "../MPC_Images/Baxibox2.png";
import img15 from "../MPC_Images/IMG-20240601-WA0016.jpg";

const clients = [
  { id: 1, name: "P & G", image: img1 },
  { id: 2, name: "Society for Family Health", image: img2 },
  { id: 3, name: "Nestle", image: img3 },
  { id: 4, name: "Johnson", image: img4 },
  { id: 5, name: "Skye Bank", image: img5 },
  { id: 6, name: "De-United Foods-Indomie", image: img6 },
  { id: 7, name: "Samsung", image: img7 },
  { id: 8, name: "GlaxoSmithkline", image: img8 },
  { id: 9, name: "IFA Nigeria", image: img9 },
  { id: 10, name: "Promasidor", image: img10 },
  { id: 11, name: "Etisalat", image: img11 },
  { id: 12, name: "Lornamead", image: img12 },
  { id: 13, name: "Fireman", image: img13 },
  { id: 14, name: "Baxi box", image: img14 },
  { id: 15, name: "Heaven joy custard", image: img15 },
];

const Clients = () => {
  return (
    <div style={styles.clientsSection} id="clients">
      <div style={styles.container}>
        <h2 style={styles.sectionHeading}>Our Clients</h2>
        <div style={styles.clientsGrid}>
          {clients.map((client) => (
            <div
              key={client.id}
              style={styles.clientCard}
              onMouseEnter={(e) => {
                e.currentTarget.style.transform = styles.clientCardHover.transform;
                e.currentTarget.style.boxShadow = styles.clientCardHover.boxShadow;
                e.currentTarget.style.backgroundColor = styles.clientCardHover.backgroundColor;
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.transform = 'none';
                e.currentTarget.style.boxShadow = styles.clientCard.boxShadow;
                e.currentTarget.style.backgroundColor = styles.clientCard.backgroundColor;
              }}
            >
              <img
                src={client.image}
                alt={client.name}
                style={styles.clientImage}
                onMouseEnter={(e) => {
                  e.currentTarget.style.transform = styles.clientImageHover.transform;
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.transform = 'none';
                }}
              />
              <p style={styles.clientName}>{client.name}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const styles = {
  clientsSection: {
    backgroundColor: '#f9f9f9',
    padding: '60px 20px',
  },
  container: {
    maxWidth: '1200px',
    margin: '0 auto',
    padding: '0 20px',
    paddingTop: "30px",
  },
  
  sectionHeading: {
    fontSize: '36px',
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: '40px',
    fontFamily: "Karla, sans-serif",
    textShadow: "2px 4px 8px rgba(0, 0, 0, 0.3)",
    fontVariant: "small-caps",
    color: "#3681D1",
  },
  clientsGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(280px, 1fr))',
    gap: '30px',
  },
  clientCard: {
    backgroundColor: '#fff',
    borderRadius: '12px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.15)',
    textAlign: 'center',
    padding: '30px',
    transition: 'transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '250px',
  },
  clientCardHover: {
    transform: 'translateY(-10px)',
    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
    backgroundColor: '#f0f0f0',
  },
  clientImage: {
    // width: '200px',
    // height: '200px',
    borderRadius: '50%',
    marginBottom: '20px',
    transition: 'transform 0.3s ease',
  },
  clientImageHover: {
    transform: 'scale(1.1)',
  },
  clientName: {
    fontSize: '20px',
    fontWeight: '600',
    color: '#333',
  },
};

export default Clients;
