import React from "react";
import "./SocialMediaStyle.css";
import "@fortawesome/fontawesome-free/css/all.min.css"; // Ensure Font Awesome is imported

const SocialMedia = () => {
  return (
    <div className="social-media-buttons">
      <a
        href="https://www.facebook.com/share/1qhB3sdZUiGtww7G/?mibextid=qi2Omg"
        className="social-media-button facebook"
      >
        <i className="fab fa-facebook-f"></i>
      </a>

      <a
        href="https://x.com/MPConceptsLtd?t=umpOndyt6WWMytV1NPCmEA&s=08"
        className="social-media-button x-twitter"
      >
        <i className="fab fa-x-twitter"></i>
      </a>
    </div>
  );
};

export default SocialMedia;
