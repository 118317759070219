import React from "react";

const Footer = () => {
  const address = "8, Omololu Olootu Street, Off Agbe Road, New Oko-Oba, Agege, Lagos, Nigeria.";
  const links = [
    { name: "About", to: "#" },
    { name: "Services", to: "#" },
    { name: "Contact", to: "#" },
  ];
  const services = [
    { name: "Event Planning", to: "#" },
    { name: "Schools Activations", to: "#" },
    { name: "MPC Road Shows", to: "#" },
    { name: "Product Sampling", to: "#" },
  ];
  const emails = ["info@mpcinternational.org", "mpcnigeria@yahoo.com", "mpcnigeria@mpcinternational.org"];
  const phones = ["08101117777", "08099999777"];

  return (
    <footer className="bg-black text-white py-12">
      <div className="container mx-auto px-6">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-8">
          <div>
            <h3 className="text-2xl font-bold mb-4">MPC International</h3>
            <p className="text-lg">{address}</p>
          </div>
          <div>
            <h3 className="text-2xl font-bold mb-4">Links</h3>
            <ul className="space-y-2 text-lg">
              {links.map((link, index) => (
                <li key={index}>
                  <a href={link.to} className="hover:text-blue-400 transition-colors duration-200">{link.name}</a>
                </li>
              ))}
            </ul>
          </div>
          <div>
            <h3 className="text-2xl font-bold mb-4">Our Services</h3>
            <ul className="space-y-2 text-lg">
              {services.map((service, index) => (
                <li key={index}>
                  <a href={service.to} className="hover:text-blue-400 transition-colors duration-200">{service.name}</a>
                </li>
              ))}
            </ul>
          </div>
          <div>
            <h3 className="text-2xl font-bold mb-4">Contact</h3>
            <div className="mb-4">
              <h4 className="text-xl font-semibold">Email:</h4>
              {emails.map((email, index) => (
                <p key={index} className="text-lg">{email}</p>
              ))}
            </div>
            <div>
              <h4 className="text-xl font-semibold">Phone:</h4>
              {phones.map((phone, index) => (
                <p key={index} className="text-lg">{phone}</p>
              ))}
            </div>
          </div>
        </div>
        <div className="text-center mt-8">
          <p className="text-lg">&copy; {new Date().getFullYear()} MPC International. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
