import React, { useState } from "react";
import { HashLink } from "react-router-hash-link";

const NavLinks = () => {
  // State to track the index of the hovered link
  const [hoveredLink, setHoveredLink] = useState(null);

  // Inline styles for the underline effect
  const underlineStyle = {
    position: "absolute",
    bottom: "-3px",
    left: "0",
    width: "100%",
    height: "2px",
    backgroundColor: "white", // Color on hover
    transition: "height 0.3s ease",
    display: hoveredLink !== null ? "block" : "none",
  };

  // Link data
  const links = [
    { text: "Home", to: "/#home" },
    { text: "About", to: "/#about" },
    { text: "Services", to: "/#services" },
    { text: "Clients", to: "/#clients" },
    { text: "Contact Us", to: "/contact#contact" },
  ];

  return (
    <>
      {links.map((link, index) => (
        <HashLink
          key={index}
          className="px-4  text-black relative"
          style={styles.navLinks}
          smooth
          to={link.to}
          onMouseEnter={() => setHoveredLink(index)}
          onMouseLeave={() => setHoveredLink(null)}
        >
          {link.text}
          {hoveredLink === index && <span style={underlineStyle}></span>}
        </HashLink>
      ))}
      {/* Include your commented code here */}
    </>
  );
};

const styles = {
  navLinks: {
    textShadow: "2px 4px 8px rgba(0, 0, 0, 0.3)",
    fontVariant: "small-caps",
    fontWeight: "bold",
    fontSize: "1.2rem",
    letterSpacing: "1px",
    color: "white",
    // margin: "20px 0",
  },
};

export default NavLinks;
