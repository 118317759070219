import React from "react";
import Clients from "../components/Clients";
import Cta from "../components/Cta";
import Footer from "../components/Footer";
import Hero from "../components/BackgroundImg/Hero";
import AboutUs from "../components/AboutUs";
import Portfolio from "../components/Portfolio";
import Services from "../components/Services";
import Home from "../components/Home";
import Users from "../components/Users/Users";
import SocialMedia from "../components/SocialMedia/SocialMedia";
import UserReview from "../components/UserReview";

const MainPage = () => {
  return (
    <div style={{overflowX: "hidden"}}>
      <Home />
      <Users/>
      <SocialMedia/>
      <UserReview/>
      <Hero />
      <AboutUs />
      <Services />
      <Portfolio />
      <Clients />
      <Cta />
      <Footer />
    </div>
  );
};

export default MainPage;
